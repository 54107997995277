<template>
  <v-dialog
    :value="value" 
    persistent 
    @click:outside="close" 
    :max-width="selectedContract.has_exception ? '950px' : '650px'">
    <v-card>
      <v-toolbar dense color="main" dark>
        <v-toolbar-title>
          <v-card-title>
            Warnings / Errors
          </v-card-title>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-4 pb-1">
        <v-alert v-if="selectedContract.warning_reason" class="my-4" color="orange lighten-3">
          <v-row>
            <v-col cols="auto">
              <v-icon class="ml-2">mdi-alert</v-icon>
            </v-col>
            <v-col>
              Warning: {{ selectedContract.warning_reason }}
            </v-col>
          </v-row>
          <v-row v-if="showWarningLog" class="mt-4 mb-1">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" outlined class="mx-auto" @click="downloadWarningLog">
                  Download Log
                  <v-icon right>
                    mdi-file-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Download warning log file</span>
            </v-tooltip>
          </v-row>
        </v-alert>
        <v-alert v-if="selectedContract.failure_reason" class="my-4" color="red lighten-3">
          <v-row>
            <v-col cols="auto">
              <v-icon class="ml-2">mdi-close-box</v-icon>
            </v-col>
            <v-col>
              Error: {{ selectedContract.failure_reason }}
            </v-col>
          </v-row>
          <v-row v-if="showErrorLog" class="mt-3 mb-1">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" outlined class="mx-auto" @click="downloadWarningLog">
                  Download Log
                  <v-icon right>
                    mdi-file-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Download warning log file</span>
            </v-tooltip>
          </v-row>
        </v-alert>
      </v-card-text>
      <v-container fluid v-if="selectedContract.has_exception" class="ma-0 pa-0">
        <ContractExceptionItems 
          :selectedContract="selectedContract">
        </ContractExceptionItems>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
const ContractExceptionItems = () => import('@/components/contracts/ContractExceptionItems.vue')
export default {
  name: 'ContractErrorsModal',
  components: { ContractExceptionItems },
  mixins: [displayAlert, utils],
  props: {
    value: Boolean,
    selectedContract: Object
  },
  computed: {
    showErrorLog () {
      return this.selectedContract.failure_reason?.toLowerCase()?.includes('see log for more details')
    },
    showWarningLog () {
      return (this.$auth.tenant !== 'alliance-retail-group') 
        || this.selectedContract.warning_reason?.toLowerCase()?.includes('see log for more details')
    }
  },
  methods: {
    close() {
      this.$emit('closeErrors')
    },
    async downloadWarningLog () {
      try {
        const { data } = await ContractUpload.downloadWarningLog(this.selectedContract.upload_id)
        if (data) {
          location.href = data.url
        }
      } catch (err) {
        this.handleError(err)
      }
    },
  }
}
</script>